function getHeyflowNavId(realId) {
   const navId = Object.keys(window.heyflow).filter(id => id.indexOf(realId) === 0);
   
   if (!navId[0]) console.error('Can not find Heyflow id');
   
   return navId[0];
}

function getElementRoot(realId) {
    const embeddedElement = document.querySelector(`[flow-id="${realId}"]`);
    
    return embeddedElement ? embeddedElement.shadowRoot : document;
}

function getCriteriaKeys(formValues, prefix) {
    return Object.keys(formValues).filter(fieldName => fieldName.indexOf(prefix) === 0);
}

let valuesHistory = {};
let checkInterval;
let noFill = false;

function handleRedirect({ event, prefix, totalCount }) {
    checkInterval = setInterval(() => {
        const criteriaKeys = getCriteriaKeys(valuesHistory, prefix);
        const checkedCriteria = criteriaKeys.filter(fieldName => valuesHistory[fieldName] === 'Yes');
        
        if (criteriaKeys.length < totalCount) return;
        else clearInterval(checkInterval);
        
        if (checkedCriteria.length < 3) {
            noFill = true;
            const realId = event.detail.flowID;
            const navId = getHeyflowNavId(realId);
            const elementRoot = getElementRoot(realId);
            const pageError = elementRoot.querySelector('section[name="sorry-screen"]');
            window.heyflow[navId].navigation.navigate('go', pageError.id, null, { noValidate: true });
            
            fillFinalInput({ 
                event, 
                selector: 'input[data-label="Quiz result"]', 
                value: 'Unsuccessful result',
                force: true,
            });
        }
    }, 5)
}

function fillFinalInput({ event, selector, value, force }) {
    setTimeout(() => {
        if (noFill && !force) return;
        
        const realId = event.detail.flowID;
        const elementRoot = getElementRoot(realId);
        const targetInput = elementRoot.querySelector(selector);
        
        if (targetInput) {
            targetInput.value = value;
            console.log('FILLED');
        } else {
            console.log('NO TARGET INPUT', selector, value, force);
        }
    }, 100);
}

window.addEventListener('heyflow-change', (event) => {
    valuesHistory = event.detail.fieldsSimple || {};
});

window.addEventListener('heyflow-screen-view', (event) => {
    if (event.detail.screenName === 'employment-options-o1a') {
        handleRedirect({ event, prefix: 'criteria', totalCount: 8 });
    }
    if (event.detail.screenName === 'employment-options-o1b') {
        handleRedirect({ event, prefix: 'o1b_criteria', totalCount: 6 });
    }
    if (event.detail.screenName === 'sorry-screen') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Quiz result"]', 
            value: 'Unsuccessful result'
        });
    }
    if (event.detail.screenName === 'sorry-screen-2') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Result"]', 
            value: 'Unsuccessful result'
        });
    }
    if (event.detail.screenName === 'o1a') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="O1A result"]', 
            value: 'O1A success'
        });
    }
    if (event.detail.screenName === 'o1b') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="O1B result"]', 
            value: 'O1B success'
        });
    }
});

function autoSubmit(screenName) {
    const devOrigin = "https://app.heyflow.com";
    const screenNames = ['o1b', 'o1a', 'sorry-screen', 'sorry-screen-2'];
    if (screenNames.includes(screenName) && window.location.origin !== devOrigin) {
        const buttonText = 'submit-heyflow-quiz-result';
        const xpath = `//section[@name='${screenName}']//button[contains(., '${buttonText}')]`
        const button = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null ).iterateNext();
        if(button instanceof HTMLButtonElement) {
            const container = document.querySelector(`section[name="${screenName}"]`);
            setTimeout(() => {
                const newButton = button.cloneNode(true);
                newButton.style.opacity = 0;
                container.appendChild(newButton);
                newButton.click();
                setTimeout(() => {
                    container.removeChild(newButton);
                }, 5000)
            }, 2000)
        } 
    }
    
}
function autoSubmitListener(event) {
    autoSubmit(event.detail.screenName)
}
window.addEventListener('heyflow-screen-view', autoSubmitListener);

function isDev() {
  const devOrigin = "https://app.heyflow.com";
  return window.location.origin === devOrigin;
}


function customAsyleeRedirect(event) {
  const value = valuesHistory["What is your current immigration status?"];
  const flowID = event.detail.flowID;
  const screenName = event.detail.screenName;
  const prefix = screenName.split("-")[0]; // o1a or o1b
  if (
    value === "Asylee" &&
    screenName === `${prefix}-visa-list-dropdown` &&
    !isDev()
  ) {
    const nextPage = document.querySelector(
      `section[name="${prefix}-asylee-status-details"]`,
    );
    setTimeout(() => {
      window.heyflow[flowID].navigation.navigate("go", nextPage.id, null, {
        noValidate: true,
      });
    }, 0);
  }
}

window.addEventListener("heyflow-screen-leave", customAsyleeRedirect);


function customJ1Redirect(event) {
  const value = valuesHistory["What is your current immigration status?"];
  const flowID = event.detail.flowID;
  const screenName = event.detail.screenName;
  const prefix = screenName.split("-")[0]; // o1a or o1b
  if (
    value === "J" &&
    screenName === `${prefix}-visa-list-dropdown` &&
    !isDev()
  ) {
    const nextPage = document.querySelector(
      `section[name="${prefix}-j1-status-details"]`,
    );
    setTimeout(() => {
      window.heyflow[flowID].navigation.navigate("go", nextPage.id, null, {
        noValidate: true,
      });
    }, 0);
  }
}

window.addEventListener("heyflow-screen-leave", customJ1Redirect);

function hideResumeContinueButton() {
  const cvlink = valuesHistory["Please share your resume"];
  const cvpdf = valuesHistory["Please share your resume (files)-1"];

  if (!cvlink && !cvpdf) {
    const submit = document.querySelector(
      "section[name='cv-or-resume-upload'] button[onclick*='submit']",
    );
    submit.style.display = "none";
  }
}
//window.addEventListener("heyflow-init", hideResumeContinueButton);
function showResumeContinueButton() {
  const cvlink = valuesHistory["Please share your resume"];
  const cvpdf = valuesHistory["Please share your resume (files)-1"];

  if (cvlink || cvpdf) {
    const submit = document.querySelector(
      "section[name='cv-or-resume-upload'] button[onclick*='submit']",
    );
    submit.style.display = "block";
  } else {
    const submit = document.querySelector(
      "section[name='cv-or-resume-upload'] button[onclick*='submit']",
    );
    submit.style.display = "none";
  }
}
// window.addEventListener("heyflow-change", showResumeContinueButton);